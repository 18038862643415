<template>
  <!-- 5月20号开发完成  开发人员：王世龙 -->
  <div>
    <main>
      <!-- 背景1 -->
      <div class="bg1">
        <img v-lazy="require('./zhjd_img/bg1.webp')" alt="" class="bg11" />
        <span class="zhjd">智慧酒店</span>
        <span class="zhfw"
          >通过人脸识别、语音识别、红外监测等AI能力进行智慧化改造，
          以移动端应用+智能硬件为载体，为游客提供全流程智慧化服务</span
        >
      </div>
      <!-- 背景1 -->
      <!-- 背景2 -->
      <div class="bg2">
        <span class="yzsfw">一站式服务</span>
        <span class="zkty">突破行业流程阻塞点，提升住客体验</span>
        <div class="bgbg">
          <img v-lazy="require('./zhjd_img/bg.webp')" alt="" class="bg" />
       </div>
        <!-- <img src="./zhjd_img/icon1.png" alt="" class="icon1" />
        <img src="./zhjd_img/icon2.png" alt="" class="icon2" />
        <img src="./zhjd_img/icon3.png" alt="" class="icon3" />
        <img src="./zhjd_img/icon4.png" alt="" class="icon4" />
        <img src="./zhjd_img/icon5.png" alt="" class="icon5" />
        <img src="./zhjd_img/icon6.png" alt="" class="icon6" />
        <img src="./zhjd_img/icon7.png" alt="" class="icon7" />
        <img src="./zhjd_img/icon8.png" alt="" class="icon8" />
        <img src="./zhjd_img/icon9.png" alt="" class="icon9" />
        <img src="./zhjd_img/icon10.png" alt="" class="icon10" />
        <img src="./zhjd_img/icon11.png" alt="" class="icon11" />
        <img src="./zhjd_img/icon12.png" alt="" class="icon12" />
        <img src="./zhjd_img/icon13.png" alt="" class="icon13" />
        <img src="./zhjd_img/icon14.png" alt="" class="icon14" />
        <img src="./zhjd_img/icon15.png" alt="" class="icon15" />
        <img src="./zhjd_img/icon16.png" alt="" class="icon16" />
        <img src="./zhjd_img/icon17.png" alt="" class="icon17" />
        <img src="./zhjd_img/icon18.png" alt="" class="icon18" />
        <span class="rhz">入住中</span>
        <span class="rzh">入住后</span>
        <span class="rzq">入住前</span>
        <span class="wz1">360°全景选房</span>
        <span class="wz2">自助智能前台</span>
        <span class="wz3">迎宾机器人</span>
        <span class="wz4">智能门锁</span>
        <span class="wz5">AI语音管家</span>
        <span class="wz6">送物机器人</span>
        <span class="wz7">全屋物联</span>
        <span class="wz8">公共区域刷脸</span>
        <span class="wz9">周边问询</span>
        <span class="wz10">一键续住</span>
        <span class="wz11">点评反馈</span>
        <span class="wz12">PMS数据共享</span>
        <span class="wz13">订单即时确定</span>
        <span class="wz14">免身份证入住</span>
        <span class="wz15">刷脸呼梯</span>
        <span class="wz16">极速退房</span>
        <span class="wz17">自助开票</span>
        <span class="wz18">自主呼叫清扫</span>
        <span class="wz19">传统房卡</span>
        <span class="wz20">跑腿</span>
        <span class="wz21">智控灯光</span>
        <span class="wz22">刷脸就餐</span>
        <span class="wz23">电子房卡</span>
        <span class="wz24">即时购买</span>
        <span class="wz25">智控窗帘</span>
        <span class="wz26">刷脸健身</span>
        <span class="wz27">刷脸开门</span>
        <span class="wz28">智控空调</span>
        <span class="wz29">刷脸洗衣</span>
        <span class="wz30">智控电视</span>
        <span class="wz31">智控音箱</span> -->
        <el-button
          type="primary"
          round
          class="btn"
          @click="jump('/One_stop_service')"
          >查看详情
          <!-- <img src="./zhjd_img/icon19.png" alt="" class="icon19"> -->
        </el-button>
      </div>
      <!-- 背景2 -->
      <!-- 背景3 -->
      <div class="bg3">
        <span class="sjjc">大数据分析决策 </span>
        <span class="ywzb"
          >通过微信、企业微信及直播平台，最大程度拓宽客户线上触点，并依靠客户激励策略、员工跟进策略及复盘工具提升拉新、促活、转化、复购等业务指标</span
        >
        <div class="box1">
          <img src="./zhjd_img/icon20.png" alt="" class="icon20" />
          <span class="sjfx">数据分析</span>
          <span class="bb">告别各“一系统一报表”，数据不再孤立</span>
        </div>
        <div class="box2">
          <img src="./zhjd_img/icon21.png" alt="" class="icon21" />
          <span class="jyfx">经营分析 </span>
          <span class="cl">数学建模输出经营策略建议</span>
        </div>
        <div class="box3">
          <img src="./zhjd_img/icon22.png" alt="" class="icon22" />
          <span class="yhfx">用户分析</span>
          <span class="yhsj">海量准确的用户画像数据</span>
        </div>
        <el-button
          type="primary"
          round
          class="btn1"
          @click="jump('/The_data_analysis')"
          >查看详情
        </el-button>
      </div>
      <!-- 背景3 -->
      <!-- 背景4 -->
      <div class="bg4">
        <span class="qyyx">企业营销</span>
        <span class="ywzb"
          >通过微信、企业微信及直播平台，最大程度拓宽客户线上触点，并依靠客户激励策略、员工跟进策略及复盘工具提升拉新、促活、转化、复购等业务指标</span
        >
        <div class="img1">
          <img v-lazy="require('./zhjd_img/img1.webp')" alt="" class="img11" />
          <span class="fwyx">服务和营销一体化</span>
          <el-button
            type="primary"
            round
            class="btn2"
            @click="jump('/marketing')"
            >查看详情
            <!-- <img src="./zhjd_img/icon19.png" alt="" class="icon19"> -->
          </el-button>
        </div>
      </div>
      <!-- 背景4 -->
      <!-- 背景5 -->
      <div class="bg5">
        <span class="alfx">案例分享</span>
        <div class="image1">
          <img v-lazy="require('./zhjd_img/image1.webp')" class="photo1" />
          <span class="kmbg">昆明维笙望湖宾馆</span>
        </div>
        <div class="image2">
          <img v-lazy="require('./zhjd_img/image2.webp')" class="photo2" />
          <span class="shxg">丽江喜多娜三合行馆</span>
        </div>
        <div class="image3">
          <img v-lazy="require('./zhjd_img/image3.webp')" class="photo3" />
          <span class="lgjd">剑川沙溪兰林阁酒店</span>
        </div>
        <div class="image4">
          <img v-lazy="require('./zhjd_img/image4.webp')" class="photo4" />
          <span class="qejd">珠海长隆企鹅酒店</span>
        </div>
      </div>
      <!-- 背景5 -->
      <Header></Header>
      <Footer></Footer>
    </main>
  </div>
</template>

<script>
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
//引入jquery
export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.bg1 {
  height: 600px;
  // min-width: 1920px;
  // background: url(./zhjd_img/bg1.png);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  img[lazy="loading"] {
    width: 200px;
    height: 200px;
  }
  // 实际图片大小
  img[lazy="loaded"] {
    height: 600px;
    width: 100%;
  }
  .bg11 {
    height: 600px;
  }
  .zhjd {
    min-width: 206px;
    height: 67px;
    font-size: 48px;
    color: #ffffff;
    position: absolute;
    top: 230px;
    left: 361px;
    text-align: left;
  }
  .zhfw {
    width: 670px;
    height: 66px;
    font-size: 24px;
    color: #ffffff;
    position: absolute;
    top: 321px;
    left: 361px;
    text-align: left;
  }
}
.bg2 {
  height: 700px;
  // min-width: 1920px;
  position: relative;
  .bgbg {
    width: 1211px;
    height: 270px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 242px;
    left: 320px;
    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      height: 270px;
      width: 100%;
    }
    .bg {
      width: 1211px;
      // height: 270px;
    }
  }
  .yzsfw {
    min-width: 289px;
    height: 56px;
    font-size: 40px;
    color: #333333;
    position: absolute;
    top: 80px;
    left: 815px;
  }
  .zkty {
    min-width: 336px;
    height: 40px;
    font-size: 20px;
    color: #565656;
    position: absolute;
    top: 160px;
    left: 792px;
  }
  // .icon1 {
  //   position: absolute;
  //   top: 257px;
  //   left: 794px;
  // }
  // .icon2 {
  //   position: absolute;
  //   top: 257px;
  //   left: 861px;
  // }
  // .icon3 {
  //   position: absolute;
  //   top: 257px;
  //   left: 929px;
  // }
  // .icon4 {
  //   position: absolute;
  //   top: 257px;
  //   left: 997px;
  // }
  // .icon5 {
  //   position: absolute;
  //   top: 257px;
  //   left: 1065px;
  // }
  // .icon6 {
  //   position: absolute;
  //   top: 257px;
  //   left: 1133px;
  // }
  // .rhz {
  //   position: absolute;
  //   top: 298px;
  //   left: 739px;
  //   min-width: 59px;
  //   height: 28px;
  //   background: #ffffff;
  //   box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
  //   border-radius: 4px;
  //   font-size: 14px;
  //   color: #007aff;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }

  // .icon7 {
  //   position: absolute;
  //   top: 375px;
  //   left: 427px;
  // }
  // .icon8 {
  //   position: absolute;
  //   top: 375px;
  //   left: 536px;
  // }
  // .icon9 {
  //   position: absolute;
  //   top: 375px;
  //   left: 648px;
  // }
  // .icon10 {
  //   position: absolute;
  //   top: 375px;
  //   left: 1277px;
  // }
  // .icon11 {
  //   position: absolute;
  //   top: 375px;
  //   left: 1390px;
  // }
  // .icon12 {
  //   position: absolute;
  //   top: 375px;
  //   left: 1502px;
  // }
  // .icon13 {
  //   position: absolute;
  //   top: 452px;
  //   left: 482px;
  // }
  // .icon14 {
  //   position: absolute;
  //   top: 452px;
  //   left: 592px;
  // }
  // .icon15 {
  //   position: absolute;
  //   top: 452px;
  //   left: 704px;
  // }
  // .icon16 {
  //   position: absolute;
  //   top: 452px;
  //   left: 1219px;
  // }
  // .icon17 {
  //   position: absolute;
  //   top: 452px;
  //   left: 1331px;
  // }
  // .icon18 {
  //   position: absolute;
  //   top: 452px;
  //   left: 1433px;
  // }
  .rzh {
    position: absolute;
    min-width: 59px;
    height: 28px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    font-size: 14px;
    color: #007aff;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 414px;
    left: 1167px;
  }
  .rzq {
    position: absolute;
    min-width: 59px;
    height: 28px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    font-size: 14px;
    color: #007aff;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 413px;
    left: 360px;
  }
  .wz1 {
    min-width: 74px;
    height: 17px;
    font-size: 12px;
    color: #007aff;
    position: absolute;
    top: 341px;
    left: 402px;
  }
  .wz2 {
    min-width: 72px;
    height: 17px;
    font-size: 12px;
    color: #007aff;
    position: absolute;
    top: 341px;
    left: 513px;
  }
  .wz3 {
    min-width: 60px;
    height: 17px;
    font-size: 12px;
    color: #007aff;
    position: absolute;
    top: 341px;
    left: 631px;
  }
  .wz4 {
    min-width: 48px;
    height: 17px;
    font-size: 12px;
    color: #007aff;
    position: absolute;
    top: 329px;
    left: 782px;
  }
  .wz5 {
    min-width: 60px;
    height: 17px;
    font-size: 12px;
    color: #007aff;
    position: absolute;
    top: 329px;
    left: 845px;
  }
  .wz6 {
    min-width: 60px;
    height: 17px;
    font-size: 12px;
    color: #007aff;
    position: absolute;
    top: 329px;
    left: 912px;
  }
  .wz7 {
    min-width: 48px;
    height: 17px;
    font-size: 12px;
    color: #007aff;
    position: absolute;
    top: 329px;
    left: 986px;
  }
  .wz8 {
    min-width: 72px;
    height: 17px;
    font-size: 12px;
    color: #007aff;
    position: absolute;
    top: 329px;
    left: 1042px;
  }
  .wz9 {
    min-width: 48px;
    height: 17px;
    font-size: 12px;
    color: #007aff;
    position: absolute;
    top: 329px;
    left: 1122px;
  }
  .wz10 {
    min-width: 48px;
    height: 17px;
    font-size: 12px;
    color: #007aff;
    position: absolute;
    top: 329px;
    left: 1264px;
  }
  .wz11 {
    min-width: 48px;
    height: 17px;
    font-size: 12px;
    color: #007aff;
    position: absolute;
    top: 329px;
    left: 1376px;
  }
  .wz12 {
    min-width: 74px;
    height: 17px;
    font-size: 12px;
    color: #007aff;
    position: absolute;
    top: 329px;
    left: 1475px;
  }
  .wz13 {
    min-width: 72px;
    height: 17px;
    font-size: 12px;
    color: #007aff;
    position: absolute;
    top: 495px;
    left: 457px;
  }
  .wz14 {
    min-width: 72px;
    height: 17px;
    font-size: 12px;
    color: #007aff;
    position: absolute;
    top: 495px;
    left: 569px;
  }
  .wz15 {
    min-width: 48px;
    height: 17px;
    font-size: 12px;
    color: #007aff;
    position: absolute;
    top: 495px;
    left: 693px;
  }
  .wz16 {
    min-width: 48px;
    height: 17px;
    font-size: 12px;
    color: #007aff;
    position: absolute;
    top: 495px;
    left: 1208px;
  }
  .wz17 {
    min-width: 48px;
    height: 17px;
    font-size: 12px;
    color: #007aff;
    position: absolute;
    top: 495px;
    left: 1320px;
  }
  .wz18 {
    min-width: 72px;
    height: 17px;
    font-size: 12px;
    color: #007aff;
    position: absolute;
    top: 495px;
    left: 1420px;
  }
  .wz19 {
    min-width: 48px;
    height: 17px;
    font-size: 12px;
    color: #666666;
    position: absolute;
    top: 354px;
    left: 782px;
  }
  .wz20 {
    min-width: 24px;
    height: 17px;
    font-size: 12px;
    color: #666666;
    position: absolute;
    top: 354px;
    left: 930px;
  }
  .wz21 {
    min-width: 48px;
    height: 17px;
    font-size: 12px;
    color: #666666;
    position: absolute;
    top: 354px;
    left: 986px;
  }
  .wz22 {
    min-width: 48px;
    height: 17px;
    font-size: 12px;
    color: #666666;
    position: absolute;
    top: 354px;
    left: 1054px;
  }
  .wz23 {
    min-width: 48px;
    height: 17px;
    font-size: 12px;
    color: #666666;
    position: absolute;
    top: 379px;
    left: 782px;
  }
  .wz24 {
    min-width: 48px;
    height: 17px;
    font-size: 12px;
    color: #666666;
    position: absolute;
    top: 379px;
    left: 918px;
  }
  .wz25 {
    min-width: 48px;
    height: 17px;
    font-size: 12px;
    color: #666666;
    position: absolute;
    top: 379px;
    left: 986px;
  }
  .wz26 {
    min-width: 48px;
    height: 17px;
    font-size: 12px;
    color: #666666;
    position: absolute;
    top: 379px;
    left: 1054px;
  }
  .wz27 {
    min-width: 48px;
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 17px;
    position: absolute;
    top: 404px;
    left: 782px;
  }
  .wz28 {
    min-width: 48px;
    height: 17px;
    font-size: 12px;
    color: #666666;
    position: absolute;
    top: 404px;
    left: 986px;
  }
  .wz29 {
    min-width: 48px;
    height: 17px;
    font-size: 12px;
    color: #666666;
    position: absolute;
    top: 404px;
    left: 1054px;
  }
  .wz30 {
    min-width: 48px;
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #666666;
    position: absolute;
    top: 429px;
    left: 986px;
  }
  .wz31 {
    min-width: 48px;
    height: 17px;
    font-size: 12px;
    color: #666666;
    position: absolute;
    top: 454px;
    left: 986px;
  }
  .btn {
    width: 156px;
    height: 48px;
    font-size: 20px;
    position: absolute;
    top: 552px;
    left: 882px;
    color: #007aff;
    background-color: #ffffff;
  }
}
.bg3 {
  // min-width: 1920px;
  height: 578px;
  background-color: #f6f8fb;
  position: relative;
  .sjjc {
    min-width: 292px;
    height: 60px;
    font-size: 40px;
    color: #333333;
    position: absolute;
    top: 80px;
    left: 815px;
  }
  .ywzb {
    width: 849px;
    height: 80px;
    font-size: 20px;
    color: #565656;
    position: absolute;
    top: 160px;
    left: 535px;
  }
  .box1 {
    min-width: 380px;
    height: 150px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 240px;
    left: 360px;
    .icon20 {
      width: 68px;
      height: 68px;
      position: absolute;
      top: 41px;
      left: 28px;
    }
    .sjfx {
      min-width: 80px;
      height: 28px;
      font-size: 20px;
      color: #333333;
      position: absolute;
      top: 43px;
      left: 112px;
    }
    .bb {
      width: 240px;
      height: 20px;
      font-size: 14px;
      color: #999999;
      position: absolute;
      top: 87px;
      left: 112px;
      text-align: left;
    }
  }
  .box2 {
    min-width: 380px;
    height: 150px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 240px;
    left: 770px;

    .icon21 {
      width: 68px;
      height: 68px;
      position: absolute;
      top: 41px;
      left: 28px;
    }
    .jyfx {
      min-width: 87px;
      height: 28px;
      font-size: 20px;
      color: #333333;
      position: absolute;
      top: 43px;
      left: 112px;
    }
    .cl {
      min-width: 168px;
      height: 20px;
      font-size: 14px;
      color: #999999;
      position: absolute;
      top: 87px;
      left: 112px;
    }
  }
  .box3 {
    min-width: 380px;
    height: 150px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 240px;
    left: 1180px;
  }
  .icon22 {
    width: 68px;
    height: 68px;
    position: absolute;
    top: 41px;
    left: 28px;
  }
  .yhfx {
    min-width: 80px;
    height: 28px;
    font-size: 20px;
    color: #333333;
    position: absolute;
    top: 43px;
    left: 112px;
  }
  .yhsj {
    min-width: 154px;
    height: 20px;
    font-size: 14px;
    color: #999999;
    position: absolute;
    top: 87px;
    left: 112px;
  }
  .btn1 {
    width: 156px;
    height: 48px;
    font-size: 20px;
    position: absolute;
    top: 430px;
    left: 882px;
    background-color: #ffffff;
    color: #007aff;
  }
}
.bg4 {
  height: 760px;
  // min-width: 1920px;
  position: relative;
  .qyyx {
    min-width: 165px;
    height: 56px;
    font-size: 40px;
    color: #333333;
    position: absolute;
    top: 80px;
    left: 877px;
  }
  .ywzb {
    width: 849px;
    height: 80px;
    font-size: 20px;
    color: #565656;
    position: absolute;
    top: 160px;
    left: 535px;
  }
  .img1 {
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: 300px;
    left: 360px;
    width: 1200px;
    height: 360px;
    justify-content: center;

    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      height: 360px;
      width: 100%;
    }
    .img11 {
      width: 1200px;
    }
    .fwyx {
      min-width: 330px;
      height: 56px;
      font-size: 40px;
      color: #ffffff;
      position: absolute;
      top: 120px;
      left: 57px;
    }
    .btn2 {
      width: 156px;
      height: 48px;
      font-size: 20px;
      position: absolute;
      top: 200px;
      left: 57px;
      background-color: rgba(0, 0, 0, 0);
    }
  }
}
.bg5 {
  // min-width: 1920px;
  height: 587px;
  background: #f6f8fb;
  position: relative;
  .alfx {
    min-width: 165px;
    height: 56px;
    font-size: 40px;
    color: #333333;
    position: absolute;
    top: 80px;
    left: 878px;
  }
  .image1 {
    min-width: 277px;
    height: 291px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 196px;
    left: 360px;
    .photo1 {
      min-width: 277px;
      height: 218px;
    }
    .kmbg {
      min-width: 150px;
      height: 25px;
      font-size: 18px;
      color: #333333;
      position: absolute;
      top: 242px;
      left: 64px;

      line-height: 25px;
    }
  }
  .image2 {
    min-width: 277px;
    height: 291px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 196px;
    left: 667px;
    .photo2 {
      min-width: 277px;
      height: 218px;
    }
    .shxg {
      min-width: 169px;
      height: 25px;
      font-size: 18px;
      color: #333333;
      position: absolute;
      top: 242px;
      left: 54px;

      line-height: 25px;
    }
  }
  .image3 {
    min-width: 277px;
    height: 291px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 196px;
    left: 974px;
    .photo3 {
      min-width: 277px;
      height: 218px;
    }
    .lgjd {
      min-width: 169px;
      height: 25px;
      font-size: 18px;
      color: #333333;
      position: absolute;
      top: 242px;
      left: 54px;
    }
  }
  .image4 {
    min-width: 277px;
    height: 291px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 196px;
    left: 1281px;
    .photo4 {
      min-width: 277px;
      height: 218px;
    }
    .qejd {
      min-width: 169px;
      height: 25px;
      font-size: 18px;
      color: #333333;
      position: absolute;
      top: 242px;
      left: 50px;
    }
  }
}
</style>
